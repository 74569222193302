<template>

    

    <div style="padding:20px;">

        <v-card class="" elevation="" max-width="" style="margin-top:20px;" >
            <v-card-text>
                <div style="">
                    <div style="margin-bottom:20px;">
                         <v-container class="">
                            <v-row>
                              <v-col cols="12" sm="10">
                                <v-text-field type="text" placeholder="pesquisar" v-model="textopesquisar" hide-details dense outlined color></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="2">
                                <v-btn medium block elevation="" @click="pesquisarArtigo()" color="info">Pesquisar</v-btn>
                              </v-col>
                            </v-row>
                          </v-container>
                    </div>
                </div>
            </v-card-text>               
        </v-card>

        <v-card class="" elevation="" max-width="" style="margin-top:20px;" >
            <v-card-text>
                    <div style="display:flex;">
                        <div>
                            <h2>Categorias</h2>
                        </div>
                        <div style="margin-left:10px;margin-top:5px;">
                            <a @click="()=>{$router.push('/CriarArtigo')}" color="info" >Criar Artigo</a>
                        </div>
                    </div>
                    <div v-for="cat in listadecategorias" :key="cat.id" style="margin-top:20px;margin-left:20px;">
                        <a style="font-size:20px;" @click="()=>{$router.push('/ListaDeArtigos/'+cat.id)}">
                            {{cat.nome}}
                        </a>
                    </div>
            </v-card-text>               
        </v-card>



    </div> 

    
</template>

<script>

import api from '../services/api'
const config = { headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`} };

export default {
    name:'Robos',
    components: {
    },
    data(){
        return{
            listadecategorias:[],
            textopesquisar:null,
        }
    },
    watch: {
        
    },
    methods:{
        buscarCategorias(){
            api().post('/artigo/buscarCategorias', {id:null}, config).then(resp=>{
                this.listadecategorias = resp.data
            })
        },
        pesquisarArtigo(){
            this.$router.push('/ListaDeArtigosPesquisado?textopesquisar='+this.textopesquisar)
            // let body = {
            //     buscaGenericaPorTexto:this.textopesquisar
            // }

            // api().post('/artigo/buscarArtigos', body, config).then(resp=>{
            //     this.listadecategorias = resp.data
            // })
        }
    },

    
    async mounted(){
        this.buscarCategorias()
    }
    
}
</script>

<style>
    .linhaFormulario{
        display: flex;
        flex-direction: row;
        /* background-color:red; */
        padding-left: 10%;
        padding-right: 10%;
    }

    .celularCampoForm{
        width:25%;
        padding:10px;
        /* background-color: green; */
    }

    .theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
        color: rgba(0, 0, 0);
    }

</style>